/*
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: 小白
 * @Date: 2022-04-25 15:58:33
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-31 13:49:00
 * @FilePath: /siana_admin/src/api_new/api.js
 */

// export const baseUrl = 'https://gptnew.v-wim.xyz'
// export const baseUrl = 'https://6e8d-61-176-96-198.ngrok-free.app'
let baseUrl
console.log(process.env);
switch (process.env.NODE_ENV) {
  case 'development':
    // baseUrl = 'https://6e8d-61-176-96-198.ngrok-free.app'
    // baseUrl = 'https://gptnew.v-wim.xyz'
    // baseUrl = 'http://192.168.2.17:8081'
    // baseUrl = 'http://47.88.15.91:8081'
    // baseUrl = 'http://www.useai.work/api'
    // baseUrl = 'https://aigo.work/api'
    // baseUrl = 'http://192.168.124.62:8081'
    // baseUrl = 'http://192.168.124.67:8083'
    // baseUrl = 'http://192.168.124.67:8081'
    // baseUrl = 'http://39.99.245.79/api/',

    baseUrl = 'https://test.aigo.work/api/'

    break
    case 'qa':
        // baseUrl = 'https://6e8d-61-176-96-198.ngrok-free.app'
        // baseUrl = 'https://gptnew.v-wim.xyz'
        // baseUrl = 'http://192.168.2.17:8081'
        // baseUrl = 'http://47.88.15.91:8081'
        // baseUrl = 'http://www.useai.work/api'
        // baseUrl = 'https://aigo.work/api'
        // baseUrl = 'http://192.168.124.62:8081'
        // baseUrl = 'http://39.99.245.79/api/'
        baseUrl = 'https://test.aigo.work/api/'

        break
    case 'qaserver':
        // baseUrl = 'https://6e8d-61-176-96-198.ngrok-free.app'
        // baseUrl = 'https://gptnew.v-wim.xyz'
        // baseUrl = 'http://192.168.2.17:8081'
        // baseUrl = 'http://47.88.15.91:8081'
        // baseUrl = 'http://www.useai.work/api'
        // baseUrl = 'https://aigo.work/api'
        // baseUrl = 'http://192.168.124.62:8081'
        baseUrl = "http://127.0.0.1:8083";
    
        break
  case 'production':
    // baseUrl = 'https://gptnew.v-wim.xyz'
    // baseUrl = 'http://192.168.2.17:8081'
    // baseUrl = 'http://47.88.15.91:8081'
    baseUrl = 'https://aigo.work/api'
    // baseUrl = 'http://192.168.124.67:8083'
    // baseUrl = 'http://192.168.124.67:8081'
    // baseUrl = "http://192.168.124.67:8083";
    // baseUrl = 'http://127.0.0.1:8083'
    // baseUrl = 'http://39.99.245.79/api/'
    break
  case 'alone':
    baseUrl = 'http://10.66.202.47:38383/api/'
    break
}
export const HTTP_URI = {
  BASEURL: baseUrl,
  LOGIN: baseUrl + '/auth/admin/login',
  HOME: baseUrl + '/sys/user/admin/home',
  USERLIST: baseUrl + '/sys/user/queryPage',
  USEREDIT: baseUrl + '/sys/user/update',
  USERADD: baseUrl + '/sys/user/add',
  USERDELETE: baseUrl + '/sys/user/delete',

  SYSTEMSETTING: baseUrl + '/sys/config/queryPage',
  SYSTEMEDIT: baseUrl + '/sys/config/update',
  PAYSETTING: baseUrl + '/sys/pay/config/query',
  PAYSETTINGEDIT: baseUrl + '/sys/pay/config/update',

  PRODUCTLIST: baseUrl + '/sys/product/queryPage',
  PRODUCTADD: baseUrl + '/sys/product/add',
  PRODUCTUPDATE: baseUrl + '/sys/product/update',
  PRODUCTDELETE: baseUrl + '/sys/product/delete',

  GPTKEYLIST: baseUrl + '/sys/gpt/key/queryPage',
  GPTKEYUPDETE: baseUrl + '/sys/gpt/key/updateState',
  GPTKEYADD: baseUrl + '/sys/gpt/key/add',
  GPTKEYDELETE: baseUrl + '/sys/gpt/key/delete',
  GPTKEYBILLING: baseUrl + '/sys/gpt/key/billing',

  ORDERLIST: baseUrl + '/sys/order/query',
  ORDERCHECKED: baseUrl + '/sys/order/yi/return/url',

  EMAILLIST: baseUrl + '/sys/email/config/queryPage',
  EMAILADD: baseUrl + '/sys/email/config/add',
  EMAILUPDATE: baseUrl + '/sys/email/config/update',
  EMAILDELETE: baseUrl + '/sys/email/config/delete',

  CARDPINLIST: baseUrl + '/sys/card/pin/queryPage',
  CARDPINADD: baseUrl + '/sys/card/pin/add',
  CARDPINUPDATE: baseUrl + '/sys/card/pin/update',
  CARDPINDELETE: baseUrl + '/sys/card/pin/delete',
  CARDPINALLLIST: baseUrl + '/sys/card/pin/getAllCardPin',

  ERRORLOG: baseUrl + '/sys/error/message/queryPage',

  VIP_PAGE: baseUrl + '/vip-package/selectPage',
  VIP_ADD: baseUrl + '/vip-package/insert',
  VIP_UPDATE: baseUrl + '/vip-package/update',
  VIP_DELETE: baseUrl + '/vip-package/delete',

  MONEY_CONFIG: baseUrl + '/sys/generalConfig/setInviterCommissionFeeOptionConfig',
  GET_MONEY_CONFIG: baseUrl + '/sys/generalConfig/getInviterCommissionFeeOptionConfig',

  GET_COMMISSION_CONFIG: baseUrl + '/sys/generalConfig/getInviterCommissionRatioConfig',
  SET_COMMISSION_CONFIG: baseUrl + '/sys/generalConfig/setInviterCommissionRatioConfig',

  GET_ORDER_WITHDRAWPAGE: baseUrl + '/sys/eventAndBill/getOrderWithdrawPage',

  UPDATE_ORDER_WITHDRAW_STATUS: baseUrl + '/sys/eventAndBill/updateOrderWithdrawState',


  give_FREE_DAYS  : baseUrl + '/sys/order/giveFreeDays',

  GET_PROMPT_LOG_LIST: baseUrl + '/sys/promptLog/query',



  GET_queryApps: baseUrl + '/v2/sys/app/queryApps',

  GET_auditApp: baseUrl + '/v2/sys/app/auditApp',

  SET_AppVersionOffline: baseUrl + '/v2/sys/app/setAppVersionOffline',


  SET_AppVersionOnline: baseUrl + '/v2/sys/app/setAppVersionOnline',

  
  GET_App: baseUrl + '/v2/sys/app/getApp',

  UPDATE_AppSort: baseUrl + '/v2/sys/app/updateAppSort',


  updateAppCategory: baseUrl + '/v2/sys/app/updateAppCategory',

  queryCategory: baseUrl + "/v2/client/appCategory/queryCategory",

  addGoods: baseUrl + "/v2/sys/goods/addGoods",

  queryGoods: baseUrl + "/v2/sys/goods/queryGoods",

  updateGoods: baseUrl + "/v2/sys/goods/updateGoods",

  queryQuestionAnswer: baseUrl + "/v2/sys/chatLog/queryQuestionAnswer",

  queryChatDetail: baseUrl + "/v2/sys/chatLog/queryChatDetail",

  updateApp: baseUrl + "/v2/sys/app/updateApp",

  getModels: baseUrl + "/v2/sys/gptModel/getModels",

  getAppModels: baseUrl + "/v2/sys/gptModel/getAppModels",
  
  updateModel: baseUrl + "/v2/sys/gptModel/updateModel",

  
  queryOrganization: baseUrl + "/v2/sys/organization/queryOrganization",

  
  getOrgGoodsList: baseUrl + "/v2/sys/organization/getOrgGoodsList",


  addOrgGoods: baseUrl + "/v2/sys/organization/addOrgGoods",

  
  updateOrgGoods: baseUrl + "/v2/sys/organization/updateOrgGoods",


  queryConfig: baseUrl + "/v2/sys/appWxConfig/queryConfig",

  
  addConfig: baseUrl + "/v2/sys/appWxConfig/addConfig",

  updateConfig: baseUrl + "/v2/sys/appWxConfig/updateConfig",

  queryGoodsCoupon: baseUrl + "/v2/sys/goodsCoupon/queryGoodsCoupon",
  

  
}
export default { HTTP_URI, baseUrl }
